export const colors = {
  skyBlue:'#6BFFFF',
  mainColor:'#7843e9',
  secondaray:'#000000', // lightbrown
  lightBlack:'#2F2F2F'
}

export const adminMailId = 'vidit@viionn.com'



